<template>
   <div class="rating">
    <ul class="list" :key="counter">
      <li @click="rate(star)" v-for="(star,index) in maxValue" :class="{ 'active': star <= stars }" :key="star.stars" class="star mr-3 ">
      <i v-if="type == 'star'"  :class="star <= stars ? 'fa fa-star' : 'fa fa-star-o'"></i> 
      <i v-if="type == 'smile'" :class="star <= stars ? 'fa fa-smile-o ' : 'fa fa-frown-o'"></i> 
      <p class="itemText m-0 p-0"> {{index +1}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
 name: 'Rating',
  props: ['grade', 'maxStars', 'hasCounter','type'],
  data() {
    return {
      stars: this.grade,
      counter: 0
    }
  },
  computed:{
      maxValue(){
          return parseInt(this.maxStars) 
      }
  },
  methods: {
    rate(star) {
      if ( star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star
      }
    }
  },
  watch:{
      maxStars(){
          this.counter++
      }
  }
}
</script>

<style scoped>

.rating{
     display: flex;
    flex-direction: column;
    justify-content: center;
    /* text-align: left; */
    text-align: var(--itemAlign);
    color:var(--controlColor)
}
.rating .list .star {
display: inline-block;
            font-size: var(--itemHeight);
            transition: all .2s ease-in-out; 
            cursor: pointer;
}
/* .rating .list:hover .star {
    color: var(--trackColor);
} */
.rating .list .star.active {
    color: var(--trackColor);
    opacity:var(--trackOpacity)
}
.rating .list .star:hover ~ .star:not(.active) {
    color: var(--controlColor);
    opacity: var(--controlOpacity);
}

.itemText{
    text-align: center;
    font-weight:var(--fontWeight);
    font-size:var(--fontSize);
    font-style:var(--fontStyle);
    text-decoration: var(--textDecoration);
    font-family: var(--fontFamily);
    color:var(--textColor);
    
    opacity:var(--textOpacity);
}
</style>